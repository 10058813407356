<template>
    <div class="container" v-if="portStatistic" :key="currentKeyValue">
        <div class="container__row">
            <div class="container__item">
                <port-overall-workload :options="portStatistic.overallWorkload" :time="time" />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item container__item_half">
                <port-overall-stage :options="portStatistic.overallStage" />
            </div>
            <div class="container__item container__item_half">
                <port-overall-status :options="portStatistic.overallStatus" />
            </div>
        </div>
    </div>
</template>

<script>
    import PortOverallWorkload from '@/components/port-components/PortOverallWorkload';
    import PortOverallStatus from '@/components/port-components/PortOverallStatus';
    import PortOverallStage from '@/components/port-components/PortOverallStage';
    import { mapGetters } from 'vuex';
    import { debounce } from 'lodash';

    export default {
        name: 'PortPreview',
        components: {
            PortOverallWorkload,
            PortOverallStatus,
            PortOverallStage,
        },
        data() {
            return {
                time: {
                    after: '',
                    before: '',
                },
                currentKeyValue: '',
            };
        },
        watch: {
            'time.after'(val) {
                val && this.fetchData();
            },
            'time.before'(val) {
                val && this.fetchData();
            },
            portStatistic(val) {
                if (val) {
                    this.currentKeyValue = this.time.after + this.time.before;
                }
            },
        },
        mounted() {
            this.time = this.setDefaultTimeValues();
            this.fetchData();
            this.$store.dispatch('getPortStatistic', this.time);
        },
        computed: {
            ...mapGetters(['portStatistic']),
        },
        methods: {
            fetchData: debounce(function() {
                this.$store.dispatch('getPortStatistic', this.time);
            }, 500),
            setDefaultTimeValues() {
                const addDays = (date, days) => {
                    let result = new Date(date);
                    result.setDate(result.getDate() + days);
                    return result;
                };
                const now = new Date();
                const prev = addDays(now, -6);
                const next = addDays(now, 8);
                return {
                    after: prev,
                    before: next,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';
</style>
